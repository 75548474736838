<template>
  <div class="login__cover">
    <div id="login" class="login__box">
      <PasswordReset
        v-if="showPasswordReset"
        @close="togglePasswordReset()"
      ></PasswordReset>

      <section class="section__login container">
        <!--
                    <div :class="{ 'signup-form': !showLoginForm }" class="">

                        <form v-if="showLoginForm" @submit.prevent>

                            <div>

                                <b-field label="Email"
                                         :type="form_email_type"
                                         :message="form_email_message">
                                    <b-input type="email"
                                             v-model.trim="loginForm.email" placeholder="e@mail.com" id="email1"
                                             maxlength="60"
                                            v-on:keyup.native="emailCheck()"
                                    >
                                    </b-input>
                                </b-field>

                            </div>

                            <div>

                                <b-field label="Password"
                                         :type="form_password_type"
                                         :message="form_password_message">
                                    <b-input type="password"
                                             v-model.trim="loginForm.password" placeholder="password" id="password1"
                                             maxlength="60"
                                             v-on:keyup.native="passwordCheck()">
                                    </b-input>
                                </b-field>

                            </div>

                            <button

                                    v-if="loginPossible()"

                                    @click="login()" class="button">Login</button>

                            <div class="extras" v-if="false">
                                <a @click="togglePasswordReset()">Forgot Password</a>
                                <a @click="toggleForm()" v-if="app_create_account">Create an Account</a>
                            </div>

                        </form>
                    </div-->

        <!--form v-else @submit.prevent>
                            <h1>Get Started</h1>
                            <div>
                                <label for="name">Name</label>
                                <input v-model.trim="signupForm.name" type="text" placeholder="Name" id="name" />
                            </div>
                            <div>
                                <label for="title">Title</label>
                                <input v-model.trim="signupForm.title" type="text" placeholder="Company" id="title" />
                            </div>
                            <div>
                                <label for="email2">Email</label>
                                <input v-model.trim="signupForm.email" type="text" placeholder="e@mail.com" id="email2" />
                            </div>
                            <div>
                                <label for="password2">Password</label>
                                <input v-model.trim="signupForm.password" type="password" placeholder="min 6 characters" id="password2" />
                            </div>
                            <button @click="signup()" class="button">Sign Up</button>
                            <div class="extras">
                                <a @click="toggleForm()">Back to Log In</a>
                            </div>
                        </form-->

        <h1>Fabric check design</h1>

        <div class="section__video">
          <div style="padding: 56.25% 0 0 0; position: relative">
            <iframe
              src="https://player.vimeo.com/video/522356075?autoplay=1&loop=1&title=0&muted=1&byline=0&portrait=0"
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>

        <div class="section__signin">
          <section id="firebaseui-auth-container"></section>
          <section id="firebaseui-auth-container-footer">
            <div
              class="firebaseui-card-footer firebaseui-provider-sign-in-footer"
            >
              <p class="firebaseui-tos firebaseui-tospp-full-message">
                By continuing, you are indicating that you accept our
                <a
                  href="https://visual.abstraktion.io/#/tos"
                  class="firebaseui-link firebaseui-tos-link"
                  target="_blank"
                  >Terms of Service</a
                >
                and
                <a
                  href="https://visual.abstraktion.io/#/policy"
                  class="firebaseui-link firebaseui-pp-link"
                  target="_blank"
                  >Privacy Policy</a
                >.
              </p>
            </div>
          </section>
        </div>

        <h2>for fashion designers</h2>

        <p>
          Make easily your fabric check patterns and colorways by composing
          threads and colors and download the repeat pattern and use it in any
          graphic 2d or 3d software. Read this french
          <a
            href="https://benude.fr/logiciel-de-creation-de-tissus-a-carreaux-en-ligne/?utm_source=fabric.abstraktion.io"
            target="_blank"
            >article in the press</a
          >. 
        </p>
        
        <p>
              Download for MAC the free tool to
              <a
                href="https://apps.apple.com/app/abstraktion/id1606949068"
                target="_blank"
                >create a palette from an image</a
              >.
            </p>

            <div class="mb-6" style="text-align: center;padding-top:2em;">
              <a
                href="https://apps.apple.com/app/abstraktion/id1606949068"
                target="_blank"
              >
                <img src="/mas.svg" class="margin: auto;"
              /></a>
            </div>

        <!-- <h2>for game designers</h2>

        <p>
          Even if you're not a stylist, you can easily generate random fabrics
          and make the ideal colorway for your characters and use them into your
          2d/3d game engine.
        </p> -->

        <p class="section__message">desktop only</p>

        <p class="section__message">
          <a href="https://www.instagram.com/abstraktion.io/" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
              />
            </svg>
          </a>
        </p>
      </section>
    </div>
  </div>
</template>

<script>
//import PasswordReset from '../components/PasswordReset'

//import Player from '@vimeo/player';

//import * as firebase from '../firestore/firestore';
//import * as fb from '../firestore/firestore'

import firebase from "firebase";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

export default {
  components: {
    //PasswordReset
  },
  data() {
    return {
      app_create_account: false,

      loginForm: {
        email: "",
        password: "",
      },
      signupForm: {
        name: "",
        title: "",
        email: "",
        password: "",
      },
      showLoginForm: true,
      showPasswordReset: false,

      form_email_type: null, // is-danger is-success
      form_email_message: null, //

      form_password_type: null, // is-danger is-success
      form_password_message: null, //
    };
  },

  mounted() {
    this.$drift.hide();

    // https://github.com/firebase/firebaseui-web#signinsuccesswithauthresultauthresult-redirecturl

    let ui = firebaseui.auth.AuthUI.getInstance();
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(firebase.auth());
    }
    let uiConfig = {
      signInSuccessUrl: "/#/app",
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        //firebase.auth.FacebookAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        signInSuccessWithAuthResult: (authResult) => {
          // this.$emit('updateAuthResult', authResult)
          // this.foo = 'bar'

          //store.commit(isClient)

          //console.log(authResult)
          return true;
        },
      },
    };
    ui.start("#firebaseui-auth-container", uiConfig);
  },

  methods: {
    loginPossible() {
      if (
        this.form_email_type == "is-success" &&
        this.form_password_type == "is-success"
      )
        return true;

      return false;
    },

    toggleForm() {
      if (this.app_create_account) this.showLoginForm = !this.showLoginForm;
    },
    togglePasswordReset() {
      this.showPasswordReset = !this.showPasswordReset;
    },
    login() {
      this.$store
        .dispatch("login", {
          email: this.loginForm.email,
          password: this.loginForm.password,
        })
        .then(
          (response) => {
            console.log(response);

            this.$buefy.toast.open({
              duration: 2000,
              message: `login`,
              position: "is-bottom",
              type: "is-success",
            });
          },
          (error) => {
            console.log(error);

            this.$buefy.toast.open({
              duration: 2000,
              message: `error`,
              position: "is-bottom",
              type: "is-danger",
            });
          }
        );
    },
    /*signup() {
                this.$store.dispatch('signup', {
                    email: this.signupForm.email,
                    password: this.signupForm.password,
                    name: this.signupForm.name,
                    title: this.signupForm.title
                })
            },*/

    emailCheck() {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (re.test(String(this.loginForm.email).toLowerCase())) {
        this.form_email_type = "is-success";
      } else {
        this.form_email_type = "is-danger";
      }
    },

    passwordCheck() {
      if (this.loginForm.password.length > 7) {
        this.form_password_type = "is-success";
      } else {
        this.form_password_type = "is-danger";
      }
    },
  },
};
</script>

<style lang="scss">
@import "~firebaseui/dist/firebaseui.css";

@import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins";

body {
  font-family: "Jost", sans-serif;
}

.extras {
  margin-top: 20px;
}

.login {
  &__cover {
    height: 100vh;
    background-size: cover;
  }
}

.section {
  &__login {
    //margin-top: 80px;

    h1 {
      font-size: 2rem;
      line-height: 2rem;
      font-weight: 900;
      text-transform: uppercase;
      margin: 15px 0;

      @include mobile {
        font-size: 1.5rem;
        line-height: 1.5rem;
      }
    }

    h2 {
      font-size: 1rem;
      font-weight: 900;
      text-transform: uppercase;
      margin: 15px 0 4px;
    }

    a,
    a:link {
      color: #333;
      transition: all 1s;
      text-decoration: underline;
    }

    a:hover {
      color: #666;
    }
  }

  &__signin {
    margin: 30px 0 30px;
  }

  &__video {
    margin: 15px 0;
  }

  &__message {
    text-align: center;
    margin: 15px auto 0;
    font-size: 0.6rem;
    text-transform: uppercase;
  }

  &__photo {
    display: none;

    @include mobile {
      display: block;
      margin-top: 30px;
    }
  }
}

.login {
  &__cover {
    background-image: url(~../assets/abstraktion-cover.jpg);

    position: fixed;
    width: 100%;

    @include tablet {
      background-position: center;
    }

    @include mobile {
      position: relative;
      width: 100%;
      background-image: none;
    }
  }

  &__box {
    max-height: 100vh;
    overflow-y: auto;

    animation: fadeIn ease 2s;
    -webkit-animation: fadeIn ease 2s;
    -moz-animation: fadeIn ease 2s;
    -o-animation: fadeIn ease 2s;
    -ms-animation: fadeIn ease 2s;

    transform: translateY(-20%);

    max-width: 550px;

    @include mobile {
      max-width: unset;
    }

    @include tablet {
      //max-width: 60%;
    }

    margin: 20vh auto;
    padding: 40px;
    background-color: #fff;
    border-radius: 10px;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
